import React from "react";
import Card from "react-bootstrap/Card";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
SyntaxHighlighter.registerLanguage("jsx", jsx);
function AboutCard() {
  const funCode = `let coffee = 0;
  let code = "console.log('Hello, world!')";
  let gaming = false;
  
  // When you're a programmer:
  if (coffee < 3 && !gaming) {
    console.log("Time to code some awesome stuff!");
    coffee++;
    code += " // fueled by coffee ☕️";
  } else if (coffee >= 3 && !gaming) {
    console.log("Better make another cup of coffee...");
    coffee++;
    code += " // still fueled by coffee ☕️";
  } else if (coffee >= 3 && gaming) {
    console.log("Let's take a break and play some games!");
  } else {
    console.log("Need more coffee...");
    coffee++;
  }
  
  // When you're a gamer:
  if (gaming && !code.includes("// gaming")) {
    code += " // gaming 🎮";
  } else if (!gaming && code.includes("// gaming")) {
    code = code.replace(" // gaming 🎮", "");
  }`;
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey there fellow coders and tech enthusiasts! I'm{" "}
            <span className="purple">Nader Jalayeri</span>, a Frontend Developer
            based in the vibrant city of{" "}
            <span className="purple">Stockholm, Sweden</span>.
            <br />
            My mission is to make the web a more beautiful and user-friendly
            place, one line of code at a time. Currently pursuing IMSC in Maths
            and Computing at BIT Mesra, I'm constantly seeking out new
            technologies and innovative solutions to make my projects stand out.
            <br />
            <br />
            When I'm not busy typing away at my keyboard, you can find me
            indulging in some techy staff, exploring the latest development
            tools and frameworks, and cracking some fun coding memes with my
            fellow geeks. I'm also an avid reader of tech blogs and always on
            the lookout for ways to improve my skills and stay ahead of the
            curve.
            <br />
            <br />
            So, whether you need a website that's as sleek as a Tesla or an
            interface that's as engaging as a good Netflix binge, I'm your guy.
            Let's build something that'll make the world sit up and take notice
            - or at least make the internet a more entertaining place. 😉👨‍💻🚀
          </p>
          <ul>
            <li className="about-activity">
              🏔️ Mountaineering
              <p style={{ color: "rgb(155 126 172)", textAlign: "justify" }}>
                My father, the hero of my life, who is a professional mountain
                climber has taught me everything regarding all the necessary
                tips and techniques in this field. He raised me like a survivor.
                I always appreciate him for all the things he's done for me. It
                doesn't make any difference which country I live in, I always
                find some mountains to climb :), some forests or great
                landscapes to camp in. I've never found the calmness and the
                positive vibe anywhere but nature.{" "}
              </p>
            </li>
            <li className="about-activity">
              🌌🌊 Studying Space & Oceans
              <p style={{ color: "rgb(155 126 172)", textAlign: "justify" }}>
                Excursionists of the seas and the stars, greetings! You can
                typically find me deep in contemplation about the marvels of the
                universe or the ocean when I'm not busy writing code. For some
                reason, when I think of these two things, my heart rate
                increases and I get an overwhelming sensation of excitement. And
                if you're ever at a loss as to what to send me for my birthday,
                the answer is easy: anything having to do with space or the
                ocean will do! I'm always up for learning something new and
                venturing out into the unknown, whether that's a nifty new book
                about black holes or a set of scuba diving gear for my next
                undersea expedition. If you want to make my day extra special,
                instead of getting me the same old boring stuff, consider
                getting me something that will pique my interest and encourage
                me to keep exploring the world around me. Maybe we'll find an
                uncharted planet or buried wealth in the ocean together. 🚀🌊🎁
              </p>
            </li>
            <li className="about-activity">
              📷 Photography
              <p style={{ color: "rgb(155 126 172)", textAlign: "justify" }}>
                It's not only my father teaching me stuff that matters in life.
                My brother Aref somehow educated me in Photography with
                professional DSLR cameras. Camping and going deep into nature, I
                always bring my camera to the scene. Waiting for the right
                moment to get the perfect shot :). Nature, Documentary, and
                sometimes Modelling are the subjects of my photography
                activities. take a look at my Instagram you'll find some of my
                works.
              </p>
            </li>
          </ul>
          <SyntaxHighlighter language="javascript" style={dark}>
            {funCode}
          </SyntaxHighlighter>
          <footer className="blockquote-footer">Unknown!</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
